import React, { useState, useEffect, useCallback } from "react";
import { useEmblaCarousel } from "embla-carousel/react";
import { GalleryThumb } from "./GalleryThumb";
import { DotButton, PrevButton, NextButton } from "./CarouselButtons";


const GalleryCarousel = ({ slides, frontmatter }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(true);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(true);
  const [mainViewportRef, embla] = useEmblaCarousel(); 
  const [thumbViewportRef, emblaThumbs] = useEmblaCarousel({
    containScroll: "keepSnaps",
    selectedClass: ""
  });

  const onThumbClick = useCallback(
    (index) => {
      if (!embla || !emblaThumbs) return;
      if (emblaThumbs.clickAllowed()) embla.scrollTo(index);
    },
    [embla, emblaThumbs]
  );

  const onSelect = useCallback(() => {
    if (!embla || !emblaThumbs) return;
    setSelectedIndex(embla.selectedScrollSnap());
    emblaThumbs.scrollTo(embla.selectedScrollSnap());
  }, [embla, emblaThumbs, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);


  return (
    <>
      <div className="embla flex flex-wrap">
        <div className="embla__viewport w-full md:w-2/3 bg-gray-100 relative" ref={mainViewportRef}>
          <div className="embla__container">
            {!slides && <p>No images</p>}
            {slides.map((item, i) => (
              <div className="embla__slide" key={i}>
                <div className="embla__slide__inner slider_inner_height  shadow-xl">
                  <img
                    className="embla__slide__img w-full h-full"
                    src={item && item.image && item && item.image.childImageSharp && item && item.image && item && item.image.childImageSharp.fluid.src}
                    alt={item.imgtitle}
                  />
                </div>
              </div>
            ))}
          </div>

      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>

        <div className="w-full md:w-1/3 overflow-y-scroll" style={{    maxHeight: "450px"}}>
        <div className="p-6">
          <h2 className="font-semibold text-lg">{slides && slides.length} Project Photos</h2>
          <p className="text-gray-700 font-display">{frontmatter && frontmatter.title && frontmatter.title}</p>
          <p className="text-gray-700 font-display">{frontmatter && frontmatter.city && frontmatter.city}, {frontmatter && frontmatter.zip && frontmatter.zip}</p>
          
        <div className="embla__viewport py-2 -mx-2" ref={thumbViewportRef}>
          <div className="grid grid-cols-4 md:grid-cols-3 gap-2">
           
            {slides.map((item,i) => { 
              if(item.image && item.image.childImageSharp && item.image.childImageSharp) {
          return (
                        <GalleryThumb
                          onClick={() => onThumbClick(i)}
                          selected={i === selectedIndex}
                          imgSrc={item.image && item.image.childImageSharp && item.image.childImageSharp.fixed.src}
                          key={i}
                        />
                      )
              } else return <p>Error loading image</p>
             })}
          </div>
        </div>
      </div>
        </div>
      </div>


  
    </>
  );
};

export default GalleryCarousel;
